// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

// Store
import Store from '@/Store/Global/Default'

export function parseCompaniesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idAdminCompany', '_idCompany', 'region', 'storages','actions'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center')
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('name', 'Razón Social').isSortable()
		.add('address', 'Dirección').isSortable().setSearchKey('address.name')
		.add('commune', 'Comuna').isSortable().setAlign(null, 'center').setSearchKey('address.commune')
		.add('type', 'Tipo').isSortable().setAlign(null, 'center')
		.add('isClient', '¿Es Cliente?').isSortable().setAlign(null, 'center')
		.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'code', 'name', 'address', 'commune', 'type', 'isClient', 'isValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('address')
		.add('commune')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		rut: (v: string) => {
			return v.toUpperCase()
		},

		type: (v: string) => {
			switch (v) {
				case 'C': return 'Cliente'
				case 'P': return 'Proveedor'
				case 'S': return 'Sucursal Interna'
			}
		},

		isClient: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedCompaniesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, ['_idAdminCompany', '_idCompany', 'address', 'code', 'commune', 'isClient', 'isValid', 'region', 'type'])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center').showDetailsButton()
		.add('name', 'Razón Social').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'name'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		rut: (v: string) => {
			return v.toUpperCase()
		},

		isClient: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Código']: ({ code }) => {
			return code
		},
		['Dirección']: ({ address }) => {
			return address
		},
		['Comuna']: ({ commune }) => {
			return commune
		},
		['Tipo']: ({ type }) => {
			switch (type) {
				case 'C': return 'Cliente'
				case 'P': return 'Proveedor'
				case 'S': return 'Sucursal Interna'
			}
		},
		['¿Es Cliente?']: ({ isClient }) => {
			return isClient ? 'Sí' : 'No'
		},
		['¿Esta Activa?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStoragesResponse(data: Array<any>, removeActionField?: boolean) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	const fieldsToRemove = ['_idStorage', '_idCompany', '_idTechnical', '_idUserAdmin', 'contacts', 'equipments', 'region', 'commune','actions']
	if (removeActionField) fieldsToRemove.push('actions')
	bm.removeFields(fields, fieldsToRemove)

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('codeLocation', 'Código Planta').isSortable().setAlign(null, 'center')
		.add('name', 'Nombre').isSortable()
		.add('address', 'Dirección').isSortable().setSearchKey('address.name')
		.add('company', 'Cliente').isSortable().setSearchKey('company.name')
		.add('technical', 'Técnico').isSortable().setAlign(null, 'center').setSearchKey('technical.name')
		.add('userAdmin', 'Adm. Contrato').isSortable().setAlign(null, 'center').setSearchKey('userAdmin.name')
		.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'code', 'codeLocation', 'name', 'address', 'company', 'technical', 'userAdmin', 'isValid'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		address: (v: string) => {
			if (PrimitiveTools.Strings.isInvalidOrEmpty(v)) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return v
		},

		company: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		technical: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		userAdmin: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedStoragesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idStorage', '_idCompany', '_idTechnical', '_idUserAdmin', 'address', 'codeLocation', 'commune', 'contacts', 'equipments', 'isValid', 'region'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
		.add('name', 'Nombre').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'code', 'name'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Código Planta']: ({ codeLocation }) => {
			return codeLocation
		},
		['Dirección']: ({ address }) => {
			if (PrimitiveTools.Strings.isInvalidOrEmpty(address)) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return address
		},
		['Listado de Activos']: ({ equipments }) => {
			if (Array.isArray(equipments) && equipments.length > 0) {
				return equipments.map((x) => {
					const equipment = Store.getters.getStoredEquipmentById(x)
					if (!equipment) return `Activo No Encontrado: ${ x }`
					return `${ equipment.code } (${ equipment.name })`
				})
			}
			return 'Sin activos asignados...'
		},
		['Cliente']: ({ _idCompany }) => {
			const company = Store.getters.getStoredCompanyById(_idCompany)
			return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Técnico']: ({ _idTechnical }) => {
			const user = Store.getters.getStoredUserById(_idTechnical)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['Adm. Contrato']: ({ _idAdmin }) => {
			const user = Store.getters.getStoredUserById(_idAdmin)
			return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['¿Es Cliente?']: ({ isClient }) => {
			return isClient ? 'Sí' : 'No'
		},
		['¿Esta Activa?']: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}