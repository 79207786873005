// ./
import Config from '@/config'

// Constants
import { Server }    from '@/Constants/Global/Server'
import { VueRouter } from '@/Constants/Global/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const UserFormPass = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'UserFormPass',

	props: {
		show: Boolean
	},

	data: function () {
		return {
			states: {
				isFetching: false,
				status: undefined
			}
		}
	},

	computed: {
		_isButtonVisible: function(): boolean {
			return !(this.states.status === 'sent')
		}
	},

	methods: {
		_getBasePath: function() {
			const { protocol, hostname } = Config.axios
			return `${ protocol }://${ hostname }`
		},

		_getDescription: function() {
			const { status } = this.states
			return status === 'sent'
				? 'Se ha enviado un link a su correo electronico registrado para iniciar el proceso de cambio de contraseña.'
				: 'Esta acción enviará un e-mail al correo registrado en el sistema con las instrucciones y enlace para el cambio de contraseña, tenga en cuenta que el cambio de contraseña es un proceso irreversible.'
		},

		_getSubmitButtonText: function() {
			const { isFetching } = this.states
			return isFetching ? 'Espere un momento, por favor' : 'Iniciar Proceso'
		},

		onUFPClick: async function() {
			this.setStates<UserFormPassRef['states']>({ isFetching: true })
			const { email } = Store.getters.getStoredUser

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				email,
				link: `${ this._getBasePath() }/#${ VueRouter.Global.View01.PATH }?action=ENTER_CODE&email=${ email }`
			}

			// Realizar la Petición al servidor.
			const response = await this.doFetch({ action: Server.Fetching.Method.POST, path: Server.Routes.Emails.RequestPassword, body })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				this.setStates<UserFormPassRef['states']>({ isFetching: false, status: 'sent' })
				this.showToast('Solicitud Actualizada', 'La Solicitud a sido actualizada correctamente!', 'success')
			}
		}
	}
})

// Exports
export default UserFormPass
export type UserFormPassRef = InstanceType<typeof UserFormPass>