import { render, staticRenderFns } from "./template.vue?vue&type=template&id=14e727b5&scoped=true&"
import script from "@/Components/Modules/0/UserFormPass/component.ts?vue&type=script&lang=ts&"
export * from "@/Components/Modules/0/UserFormPass/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Components/Modules/0/UserFormPass/style.scss?vue&type=style&index=0&id=14e727b5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e727b5",
  null
  
)

export default component.exports