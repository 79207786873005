// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader  from '@/Components/Global/BasicHeader/template.vue'
import DataTable    from '@/Components/Global/DataTable/template.vue'
import SidePanel    from '@/Components/Global/SidePanel/template.vue'
import UserForm     from '@/Components/Modules/0/UserForm/template.vue'
import UserFormPass from '@/Components/Modules/0/UserFormPass/template.vue'

// Components (Refs)
import { BasicHeaderRef } from '@/Components/Global/BasicHeader/component'
import { DataTableRef }   from '@/Components/Global/DataTable/component'
import { SidePanelRef }   from '@/Components/Global/SidePanel/component'

// Constants
import { Breakpoints } from '@/Constants/Global/Breakpoints'
import { Server }      from '@/Constants/Global/Server'
import { VueRouter }   from '@/Constants/Global/VueRouter'
import { Vuex }        from '@/Constants/Global/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Views
import { parseStackedStoragesResponse, parseStoragesResponse } from '@/Views/Modules/2/22/response.parser'

// Component Extend
const View02 = VueMixins(MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Global.View02.NAME,

	components: {
		BasicHeader,
		SidePanel,
		UserForm,
		UserFormPass,
		DataTable
	},

	data: function() {
		return {
			sidePanelItems: [
				{ action: 'USER_PROFILE', label: 'Perfil', icon: 'users-cog', selected: true },
				{ action: 'USER_PASSWORD', label: 'Contraseña', icon: 'pen', selected: false }
			],
			states: {
				dataTableParser:parseStoragesResponse,
				showDataTable: true,
				showUserForm: true,
				showUserFormPass: false,
				userStorages: [],
				preventDefaultStacked:true
			}
		}
	},

	created: function() {
		// this.doFetchValidationFor(Server.Fetching.Validations.STORAGES)
	},

	mounted: function() {
		this._sidePanel.setItems(this.sidePanelItems)
		this._initUserStorages()
		this._dataTable.setStates<DataTableRef['states']>({preventDefaultStacked:true})
	},

	computed: {
		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_dataTable: function(): DataTableRef {
			return this.$refs.dataTable as DataTableRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_initUserStorages: function() {
			const { storages } = Store.getters.getStoredUser
			if (storages?.length > 0) {
				// Obtener la información de las Ubicaciones del Usuario Actual.
				const aux = []
				for (const id of storages) {
					const storage = Store.getters.getStoredStorageById(id)
					if (storage) aux.push(storage)
				}

				// Mostrarlas en la Tabla.
				const { stacked } = this._dataTable.states
				this.setStates<View02Ref['states']>({ dataTableParser: stacked ? parseStackedStoragesResponse : parseStoragesResponse, userStorages: aux })

				const { fields, items } = this.states.dataTableParser(aux, true)
				this._dataTable.resetEmptyText()
				this._dataTable.setElements(fields, items)
				this._dataTable.sortOrder('code', 'asc')
			}
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Controlar la Visibilidad de los elementos según el Breakpoint.
			const stacked = breakpoint <= Breakpoints.XLarge
			this._dataTable.setStates<DataTableRef['states']>({stacked})
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
			this._initUserStorages()
		},

		onDTRefreshButtonClick() {
			this._initUserStorages()
		},

		onSPItemClick: function(item: any) {
			switch (item.action) {
				case 'USER_PROFILE':
					this.setStates<View02Ref['states']>({ showDataTable: true, showUserForm: true, showUserFormPass: false })
					break
				case 'USER_PASSWORD':
					this.setStates<View02Ref['states']>({ showDataTable: false, showUserForm: false, showUserFormPass: true })
					break
			}
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, '_initUserStorages')
	}
})

// Component Export
export default View02
export type View02Ref = InstanceType<typeof View02>