// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const UserForm = VueMixins(MixinBase,MixinResponsive).extend({
	name: 'UserForm',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			user: undefined
		}
	},

	created: function() {
		this.user = Store.getters.getStoredUser
	}
})

// Exports
export default UserForm
export type UserFormRef = InstanceType<typeof UserForm>