// Classes
import { VuexTools } from '@/Classes/Static/VuexTools'

// Components
import CompanyLogo  from '@/Components/Extras/CompanyLogo/template.vue'
import SubmitButton from '@/Components/Extras/SubmitButton/template.vue'

// Constants
import { Documents } from '@/Constants/Global/Documents'
import { VueRouter } from '@/Constants/Global/VueRouter'
import { Vuex }      from '@/Constants/Global/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const InitialsButton = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'InitialsButton',

	components: {
		CompanyLogo,
		SubmitButton
	},

	data: function() {
		return {
			initials: '??',
			popupMenu: {
				eventAttached: false,
				items: [],
				show: false
			},
			states: {
				isFetching: false,
				options: ['Administración', 'Servicios', 'CheckLists'],
				selectedOption: null,
				wasAddedAtLeastOneSystem: false
			}
		}
	},

	mounted: function() {
		// Actualiza el valor de 'initials' si el usuario a iniciado sesión correctamente.
		const user = Store.getters.getStoredUser
		if (user) {
			const nChar = user?.name ? user.name.substring(0, 1).toUpperCase() : ''
			const aChar = user?.pLastName ? user.pLastName.substring(0, 1).toUpperCase() : ''
			this.initials = nChar + aChar
		}

		// Inicializar los Items del Menu.
		this._initPopupMenuItems()
	},

	methods: {
		_callbackLogOut: async function() {
			Store.commit('destroyCredentials')
			this.onDetachDocumentMouseClick()
			this.$router.push({ name: VueRouter.Global.View00.NAME, params: { successLogout: 'true' } })
		},

		_hasPermissionToSystem: function(accessKeyName: string) {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			const permission = userPermissions[accessKeyName]?.privileges.read
			if (permission) this.setStates<InitialsButtonRef['states']>({ wasAddedAtLeastOneSystem: true })
			return permission

			// if (systemId === Values.ObjectIds.Module30) {
			// 	const permission = userPermissions.ACCESS_MODULE_30?.privileges.read
			// 	if (permission) this.setStates<InitialsButtonRef['states']>({ wasAddedAtLeastOneSystem: true })
			// 	return permission
			// }
			// else if (systemId === Values.ObjectIds.Module40) {
			// 	const permission = userPermissions.ACCESS_MODULE_40?.privileges.read
			// 	if (permission) this.setStates<InitialsButtonRef['states']>({ wasAddedAtLeastOneSystem: true })
			// 	return permission
			// }
		},

		_includeMainItems: function() {
			const { items } = this.popupMenu
			items.push({ type: 'link', icon: 'user', text: 'Mi Perfil', to: VueRouter.Global.View02.NAME })
			// items.push({ type: 'link', icon: 'bug', text: 'Reportar Error', preventDefaults: true, callback: this._callbackReportBug })
			items.push({ type: 'divider' })
		},

		_includeSystemItems: function() {
			const { items } = this.popupMenu
			const user = Store.getters.getStoredUser

			if (user._idRole === Documents.Roles.Kreis.CLIENT) {
				if (this._hasPermissionToSystem('ACCESS_MODULE_40')) items.push({ type: 'link', icon: 'external-link-square-alt', text: 'Modulo CheckLists', to: VueRouter.Modules.View40.NAME })
			}
			else {
				if (this._hasPermissionToSystem('ACCESS_MODULE_20')) items.push({ type: 'link', icon: 'external-link-square-alt', text: 'Modulo Administración',   to: VueRouter.Modules.View20.NAME })
				// if (this._hasPermissionToSystem('ACCESS_MODULE_30')) items.push({ type: 'link', icon: 'external-link-square-alt', text: 'Sistema Servicios',    to: VueRouter.Modules.View30.NAME })
				if (this._hasPermissionToSystem('ACCESS_MODULE_40')) items.push({ type: 'link', icon: 'external-link-square-alt', text: 'Modulo CheckLists',       to: VueRouter.Modules.View40.NAME })
				if (this._hasPermissionToSystem('ACCESS_MODULE_41')) items.push({ type: 'link', icon: 'external-link-square-alt', text: 'Modulo Setup CheckLists', to: VueRouter.Modules.View41.NAME })
			}

			if (this.states.wasAddedAtLeastOneSystem) items.push({ type: 'divider' })
		},

		_initPopupMenuItems: function() {
			// Asegurarse de vaciar el Array completo antes de empezar a agregar items.
			this.popupMenu.items = []

			// Nombre de Usuario.
			const { items } = this.popupMenu
			const user = Store.getters.getStoredUser
			items.push({ type: 'title', text: `${ user.name } ${ user.pLastName }` })

			// Incluir el resto de elementos.
			this._includeMainItems()
			this._includeSystemItems()

			// El ultimo item debe ser para Cerrar Sesión.
			items.push({ type: 'link', icon: 'sign-out-alt', text: 'Cerrar Sesión', preventDefaults: true, callback: this._callbackLogOut })
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onAttachDocumentMouseClick: function() {
			this.popupMenu.eventAttached = true
			document.addEventListener("click", this.onDocumentMouseClick)
		},

		onDetachDocumentMouseClick: function() {
			this.popupMenu.show = false
			this.popupMenu.eventAttached = false
			document.removeEventListener("click", this.onDocumentMouseClick)
		},

		onDocumentMouseClick: function({ target }: any) {
			if (target.parentElement === null) return
			if (target.parentElement.id === 'initials-button') return
			if (target.parentElement.id === 'popupMenu') return
			if (target instanceof HTMLSpanElement) return
			this.onDetachDocumentMouseClick()
		},

		onIBSendReport: function() {

		},

		onPopupMenuItemLinkClick: function(item: { callback: Function, preventDefaults: boolean, to: string }) {
			if (item?.preventDefaults === true) {
				item.callback()
			}
			else {
				if (this.$route.name !== item.to) {
					this.onDetachDocumentMouseClick()
					this.$router.replace({ name: item.to })
				}
			}
		},

		onTogglePopupMenu: function() {
			this.popupMenu.show = !this.popupMenu.show
			if (this.popupMenu.show === true && this.popupMenu.eventAttached === false) {
				this.onAttachDocumentMouseClick()
			}
			else {
				this.onDetachDocumentMouseClick()
			}
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPopupMenuItems')
	}
})

// Exports
export default InitialsButton
export type InitialsButtonRef = InstanceType<typeof InitialsButton>