// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Vuex } from '@/Constants/Global/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Interfaces
import { TokenizedUser } from '@/Interfaces/Models/TokenizedUser'

// Mixins
import MixinBase      from '@/Mixins/MixinBase'
import MixinComponent from '@/Mixins/MixinComponent'
import MixinFetch     from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'


// Component Extend
const SidePanel = VueMixins(MixinBase, MixinComponent, MixinFetch,MixinResponsive).extend({
	name: 'SidePanel',

	data: function() {
		return {
			items: [],
			states: {
				company: ''
			}
		}
	},
	
	mounted: function() {
		this._initCompanyName()
	},

	methods: {
		_initCompanyName: function() {
			// const _idStorage = Store.getters.getStoredUser?.storages[0]
			// const storage = Store.getters.getStoredStorageById(_idStorage)
			// if (storage) {
			// 	const company = Store.getters.getStoredCompanyById(storage._idCompany)
			// 	this.setStates<SidePanelRef['states']>({ company: company?.name || 'Empresa no encontrada...' })
			// }

			const user: TokenizedUser = Store.getters.getStoredUser
			const company = Store.getters.getStoredCompanyById(user._idCompany)
			this.setStates<SidePanelRef['states']>({ company: company?.name || 'N/A' })
		},

		_refreshItemsAndUpdateSelection: function(index: number) {
			for (let i = 0; i < this.items.length; i++) {
				const item = this.items[i]
				item.selected = i === index
			}
		},

		setItems: function(items: Array<Item>) {
			this.items = items
		},

		onSPItemClick: function (item: Item, index: number) {
			DevelopmentTools.printWarn('[SidePanel]:onSPItemClick() event triggered')
			this._refreshItemsAndUpdateSelection(index)
			this.$emit('onSPItemClick', item)
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Companies, '_initCompanyName'),
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Storages, '_initCompanyName')
	}
})

// Exports
export default SidePanel
export type SidePanelRef = InstanceType<typeof SidePanel>

// Interfaces
interface Item {
	action: string
	icon: (string | [string, string])
	label: string
	selected: boolean
	typeUser?: string
}